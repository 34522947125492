<template>
	<div
		class="countdown-container"
		:class="positionTop && 'countdown-container__top'"
	>
		<p
			v-if="!isExpired"
			class="manchet countdown__title"
		>
			{{ title }}
		</p>
		<div
			v-if="!isExpired && hasEndDate"
			class="countdown__timer"
		>
			<div class="countdown__items">
				<p class="countdown__numbers">{{ days }}</p>
				<p class="countdown__time-unit">DAGE</p>
			</div>
			<div class="countdown__items">
				<p class="countdown__numbers">{{ hours }}</p>
				<p class="countdown__time-unit">TIMER</p>
			</div>
			<div class="countdown__items">
				<p class="countdown__numbers">{{ minutes }}</p>
				<p class="countdown__time-unit">MIN.</p>
			</div>
			<div class="countdown__items">
				<p class="countdown__numbers">{{ seconds }}</p>
				<p class="countdown__time-unit">SEK.</p>
			</div>
		</div>
		<p
			v-if="isExpired"
			class="manchet countdown__title"
		>
			{{ expiredText }}
		</p>
	</div>
</template>

<script setup lang="ts">
	import { onMounted, ref } from 'vue';

	const props = defineProps<{
		title: string;
		endDate: string;
		expiredText: string;
		positionTop: boolean;
	}>();

	const days = ref();
	const hours = ref();
	const minutes = ref();
	const seconds = ref();

	const hasEndDate = ref(false);
	const isExpired = ref(false);

	onMounted(() => {
		const setCountdown = setInterval(() => {
			const countdownEndDate = new Date(props.endDate).getTime();
			const todayDate = new Date().getTime();
			const remainingTime = countdownEndDate - todayDate;

			days.value = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
			hours.value = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			minutes.value = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
			seconds.value = Math.floor((remainingTime % (1000 * 60)) / 1000);

			hasEndDate.value = true;

			if (remainingTime < 0) {
				clearInterval(setCountdown);
				isExpired.value = true;
			}
		}, 1000); // Update the countdown every 1 second
	});
</script>

<style lang="scss">
	@import './Countdown.module.scss';
</style>
